.loader {
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    position: fixed;
    z-index: 9999;
    user-select: none;
    opacity: 1;
    visibility: visible;
    transition: all 50ms ease;
    height: auto;
}

.loaderImg {
    animation: wobble-pulse 2.2s ease 0.2s infinite normal forwards;
}

.loader.loaderStarting,
.loaderStarting {
    opacity: 0 !important;
    visibility: hidden !important;
    height: 0 !important;
}

@keyframes wobble-pulse {
    0% {
        animation-timing-function: ease-out;
        transform: scale(1);
        transform-origin: center center;
    }

    5% {
        transform: rotate(-45deg) scale(1);
    }

    20% {
        animation-timing-function: ease-in;
        transform: scale(1.29) rotate(-45deg);
    }

    37% {
        animation-timing-function: ease-out;
        transform: scale(1.08) rotate(-45deg);
    }

    43% {
        animation-timing-function: ease-in;
        transform: scale(1.25) rotate(-45deg);
    }

    55% {
        animation-timing-function: ease-out;
        transform: scale(1) rotate(-30deg);
    }

    80% {
        animation-timing-function: ease-out;
        transform: scale(1) rotate(0deg);
    }
}
