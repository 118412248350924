:root {
  --success-color: #D1FADF;
  --success-active-color: #146c43;

  --primary-color: #F0F5FD;
  --primary-active-color: #4389F3;

  --warning-color: rgba(254, 239, 198, 0.30);
  --warning-active-color: #ffc107;

  --warning-strong-color: #FEEFC6;
  --warning-strong-active-color: #B54708;

  --secondary-color: #E2E3E5;
  --secondary-active-color: #868686;

  --black-color: #1B1B1B;
  --white-color: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--white-color);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-active-color);
}

.modal-content{
  border-radius: 20px!important;
}